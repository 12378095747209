import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Nav from "../components/nav"
import SEO from "../components/seo"
import "./veranstaltung.css"

const VeranstaltungTemplate = props => {
  return (
    <Layout>
      <SEO
        title={props.data.contentfulVeranstaltung.seoTitel}
        description={props.data.contentfulVeranstaltung.seoAnleser}
      ></SEO>
      <Nav />
      <div className="header__hero"></div>
      <div className="veranstaltung">
        <div className="veranstaltung__box">
          <p className="veranstaltung__datum">
            {props.data.contentfulVeranstaltung.datum}
          </p>
          <p className="veranstaltung__title">
            {props.data.contentfulVeranstaltung.titel}
          </p>
        </div>
      </div>
      <div className="veranstaltung__wrapper">
        <div className="veranstaltung__text">
          <div
            dangerouslySetInnerHTML={{
              __html: `${props.data.contentfulVeranstaltung.text.childMarkdownRemark.html}`,
            }}
          />
        </div>
      </div>
    </Layout>
  )
}

export default VeranstaltungTemplate

export const query = graphql`
  query VeranstaltungTemplate($id: String!) {
    contentfulVeranstaltung(id: { eq: $id }) {
      titel
      id
      slug
      seoTitel
      seoAnleser
      datum(formatString: "D MMMM Y", locale: "de")
      text {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
